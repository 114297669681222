import { EQimaInputBorder } from '../../abstract-input/enums/qima-input-border.enum';
import { EQimaInputSize } from '../../abstract-input/enums/qima-input-size.enum';
import { InjectionToken } from '@angular/core';
const MAX_LENGTH = 0; // unlimited
const ROW_AMOUNT = 3;
/**
 * @description
 * The token consumed by the {@link QimaInputTextareaComponent} to define the default values of its inputs
 * @type {InjectionToken<IQimaInputTextareaConfig>}
 */
export const QIMA_INPUT_TEXTAREA_CONFIG_TOKEN = new InjectionToken('', {
  factory() {
    /**
     * The fallback values should be identical as the fallback [provider]{@link QimaInputTextareaModule#forRoot} values
     */
    return {
      border: EQimaInputBorder.FULL,
      hasValidators: false,
      isDisabled: false,
      placeholder: '',
      shouldAutoFocus: false,
      size: EQimaInputSize.MEDIUM,
      maxLength: MAX_LENGTH,
      rows: ROW_AMOUNT
    };
  }
});
