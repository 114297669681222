// @ts-strict-ignore
import { ReportsManagementService } from '@admin/app/reports-management/reports-management.service';
import { QpButtonLabelComponent } from '@library/components/qp-button/components/qp-button-label/qp-button-label.component';
import { QpButtonComponent } from '@library/components/qp-button/qp-button.component';
import { EQpButtonSize, EQpButtonType, EQpButtonWidth } from '@library/components/qp-button/qp-button.models';
import { QpLabelComponent } from '@library/components/qp-label/qp-label.component';
import { QpDisabledDirective } from '@library/directives/qp-disabled/qp-disabled.directive';
import { QpNotificationBarService } from '@library/services/qp-notification-bar/qp-notification-bar.service';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { QimaInputTextareaModule, QimaOptionalType } from '@qima/ngx-qima';

export const MAXIMUM_NO_OF_IDS_ALLOWED: number = 100;

@UntilDestroy()
@Component({
  selector: 'qp-reports-management-actions',
  templateUrl: './reports-management-actions.component.html',
  styleUrls: ['./reports-management-actions.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    QpLabelComponent,
    QimaInputTextareaModule,
    TranslateModule,
    QpButtonComponent,
    QpButtonLabelComponent,
    QpDisabledDirective,
  ],
})
export class ReportsManagementActionsComponent implements OnInit {
  public generationForm: UntypedFormGroup;
  public buttonSizes: typeof EQpButtonSize = EQpButtonSize;
  public buttonTypes: typeof EQpButtonType = EQpButtonType;
  public buttonWidths: typeof EQpButtonWidth = EQpButtonWidth;

  public constructor(
    private readonly _reportsManagementService: ReportsManagementService,
    private readonly _qpNotificationBarService: QpNotificationBarService
  ) {}

  public ngOnInit(): void {
    this.generationForm = new UntypedFormGroup({
      ids: new UntypedFormControl('', [this.listOfIdsValidator, this.listOfIdsLengthValidator, Validators.required]),
    });
  }

  public listOfIdsValidator(c: UntypedFormControl): QimaOptionalType<{ validateArrayOfIds: { valid: boolean } }> {
    return /^(?=.*\d)[\d ]+$/.test(c.value)
      ? null
      : {
          validateArrayOfIds: {
            valid: false,
          },
        };
  }

  public listOfIdsLengthValidator(control: UntypedFormControl): QimaOptionalType<{ validateArrayOfIdsLength: { valid: boolean } }> {
    return control.value.trim().split(' ').length <= MAXIMUM_NO_OF_IDS_ALLOWED
      ? null
      : {
          validateArrayOfIdsLength: {
            valid: false,
          },
        };
  }

  public clear(): void {
    this.generationForm.get('ids').setValue(null);
  }

  public generate(): void {
    if (this.generationForm.invalid) {
      return;
    }

    const ids: number[] = this.generationForm
      .get('ids')
      .value.split(' ')
      .map(function (item): number {
        return parseInt(item, 10);
      });

    this._reportsManagementService
      .generatePDFReports(ids)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (): void => {
          this._qpNotificationBarService.success('✅');
          this.clear();
        },
        error: (err): void => {
          this._qpNotificationBarService.error(err.message);
          console.error(err);
        },
      });
  }
}
