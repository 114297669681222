<div class="actions">
  <div [formGroup]="generationForm" class="action">
    <div class="action__header">
      {{ 'reportsManagement.actions.pdf-generation.header' | translate }}
    </div>
    <div class="action__content">
      <qp-label>{{ 'reportsManagement.actions.pdf-generation.summary' | translate }}</qp-label>
      <qima-input-textarea formControlName="ids" [qimaInputTextareaPlaceholder]="'ex: 1234 2345 3456 4567 5678 6789'"></qima-input-textarea>
    </div>
    <div class="action__footer">
      <qp-button (click)="clear()" [size]="buttonSizes.MEDIUM" [type]="buttonTypes.SIMPLE" [width]="buttonWidths.COMPACT">
        <qp-button-label> {{ 'reportsManagement.actions.pdf-generation.clear' | translate }}</qp-button-label>
      </qp-button>
      <qp-button
        (click)="generate()"
        [qpDisabled]="generationForm.invalid"
        [size]="buttonSizes.MEDIUM"
        [type]="buttonTypes.PRIMARY"
        [width]="buttonWidths.COMPACT"
      >
        <qp-button-label> {{ 'reportsManagement.actions.pdf-generation.generate' | translate }}</qp-button-label>
      </qp-button>
    </div>
  </div>
</div>
