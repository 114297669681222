import { SERVER_API_URL } from '@admin/app/app.constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReportsManagementService {
  public adminResourceUrl = `${SERVER_API_URL}api/admin/reports`;

  public constructor(private readonly _httpClient: HttpClient) {}

  public generatePDFReports(inspectionsIds: number[]): Observable<void> {
    return this._httpClient.post<void>(this.adminResourceUrl, { inspectionsIds });
  }
}
